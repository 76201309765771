<template>
    <div class="">
        <div v-if="header_data && header_data != {}" class="pb-2 d-flex justify-space-between align-center">
            <div class="d-flex align-center">
                <img v-if="header_data.icon && header_data.icon != ''" :src="header_data.icon" alt="explore-icon">

                <p class="ma-0 pl-2 body-heading font-weight-semi-bold" align="left">{{ header_data.heading_name }}</p>
            </div>
        </div>

        <div class="">
            <div class="categories-container">
                <div v-for="category, index in categoriesToDisplay" :key="index" class="py-2 category-class">
                    <category-subcomponent class="px-0 px-sm-1" @redirectToParticularCategory="redirectToParticularCategory" @redirectOnViewMore="redirectOnViewMore" :category_data="category" :category_type="category_type"></category-subcomponent>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategorySubComponent',
    props: {
        categories: {
            type: Array,
            required: false
        },
        header_data: {
            type: Object,
            required: false
        },
        category_type: {
            type: String,
            required: false
        }
    },
    components: {
        'category-subcomponent': () => import('./categorySubComponent.vue')
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            categoryLength: null,
            categoriesToDisplay: []
        }
    },
    watch: {
		screenWidth(newWidth, oldWidth) {
		},
	},
    mounted() {
        this.screenWidth = window.innerWidth;
        window.addEventListener('resize', this.handleResize);

        if (this.screenWidth <= 600) {
            this.categoryLength = 5;
        } else if (this.screenWidth > 600 && this.screenWidth <= 900) {
            this.categoryLength = 4;
        } else if (this.screenWidth > 900 && this.screenWidth <= 1264) {
            this.categoryLength = 6;
        } else {
            this.categoryLength = 8;
        }

        if (this.categories && this.categories.length > 0) {
            this.categoriesToDisplay = this.categories.slice(0, this.categoryLength);
            this.categoriesToDisplay.push({ view_more: true });
        }
    },
    beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
    methods: {
        handleResize() {
			if (this.screenWidth <= 350) {
				this.categoryLength = 3;
			} else if (this.screenWidth > 350 && this.screenWidth <= 600) {
                this.categoryLength = 5;
            } else if (this.screenWidth > 600 && this.screenWidth <= 900) {
				this.categoryLength = 4;
			} else if (this.screenWidth > 900 && this.screenWidth <= 1264) {
                this.categoryLength = 6;
            } else {
				this.categoryLength = 8;
			}

            if (this.categories && this.categories.length > 0) {
                this.categoriesToDisplay = this.categories.slice(0, this.categoryLength);
                this.categoriesToDisplay.push({ view_more: true });
                this.$emit('handleCategorySizeChange');
            }
		},
        redirectToParticularCategory(categoryId, categoryName) {
            if (this.header_data.pagename === 'SpecializationsPage') {
                if (this.screenWidth <= 600) {
                    this.$router.push({
                        path: '/customer/viewDoctors?type=Category&type1=' + categoryId + '&type2=' + categoryName
                    });
                } else {
                    window.open(window.location.origin + '/customer/viewDoctors?type=Category&type1=' + categoryId + '&type2=' + categoryName);
                }
            } else if (this.header_data.pagename === 'AllCategoriesPage') {
                this.$router.push({
                    name: 'CatalogPage',
                    params: {
                        catalog_name: 'categories',
                        category_id: categoryId
                    }
                });
            }
        },
        redirectOnViewMore() {
            if (this.header_data.pagename == 'SpecializationsPage') {
                if (this.screenWidth <= 600 ) {
                    this.$router.push({
                        path: '/customer/specializations'
                    });
                } else {
                    window.open(window.location.origin + '/customer/specializations');
                }
            } else {
                this.$router.push({
                    name: this.header_data.pagename
                });
            }
            
        },
    }
}
</script>

<style lang="scss" scoped>

.category-class {
    @media (max-width: 600px) {
        width: 32% !important;
    }

    @media (min-width: 601px) {
        width: 120px;
    }
}

.categories-container {
    @media (max-width: 600px) {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: space-between !important;
    }

    @media (min-width: 601px) and (max-width: 900px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    @media (min-width: 901px) and (max-width: 1264px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    @media (min-width: 1265px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>

<style lang="scss">
@import '../../scss/classes.scss';
</style>